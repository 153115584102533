<template lang="pug">

  .assessments(v-if="survey_templates  && $store.state.user")
    
    .row.mt-3
        
        .col-2
            
           
        .col-9(style="max-width: 1200px")
            b-btn(variant="outline-primary", size="sm", @click="create_survey_template()", v-if="!selected") Create new survey
            template(v-if="!selected")
              div(style="overflow-y: auto", v-if="survey_templates != null").mt-4
                div.accordion
                  template(v-for="(template, i) in survey_templates")
                      b-card(no-body).mt-1
                        
                        b-card-header(header-tag="header" class="p-1" role="tab").p-2
                            .row
                              .col-4
                                b-link(v-b-toggle="'accordion-' + template._id")
                                  h5 {{template.name}}
                                .text-gray.small {{template.description}}
                                  
                              .col-4(v-if="analytics[template._id]")
                                b-overlay(:show="!analytics[template._id]")
                                  .kpi.h-mouseover-parent.text-center.mr-2
                                    .indicator {{analytics[template._id].created_at_total || 0}}
                                    .small.text-gray 
                                      | Created
                                  b-link(v-b-toggle="'accordion-' + template._id")
                                    .kpi.text-center.mr-2
                                      .indicator {{analytics[template._id].sent_at_total || 0}}
                                      .small.text-gray Sent
                                    .kpi.text-center.mr-2
                                      .indicator {{analytics[template._id].opened_at_total || 0}}
                                      .small.text-gray Opened
                                    .kpi.text-center.mr-2 
                                      .indicator {{analytics[template._id].responded_at_total || 0}}
                                      .small.text-gray Responded
                              .col-4
                        
                                .float-right
                                  b-btn-group()
                                    b-btn(size="sm", variant="outline-secondary", @click="selected=template", v-if="template.item_type == 'project_assessment'") Open
                                    b-btn(size="sm", variant="outline-secondary", @click="selected=template", v-if="template.item_type != 'project_assessment'") Open
                                    //b-btn(size="sm", variant="outline-secondary") Tracking 
                                    //b-btn(size="sm", variant="outline-secondary", v-if="template.item_type == 'project_assessment' || template.item_type == 'standard_survey'") Invite companies

                        b-collapse(:id="'accordion-'+template._id" accordion="my-accordion" :visible="i==0", role="tabpanel")
                          b-card-body(v-if="analytics[template._id] && analytics[template._id]['created_at']")
                            b-overlay(:show="!analytics[template._id]")
                                LineChart(style="height: 200px", :chartData="get_chart_data(analytics[template._id])", :options="line_options")
             
                  
            template(v-if="selected")
              .row
                .col-12
                  .float-right
                    b-btn(@click="selected = null", variant="outline-secondary", size="sm")
                      i.fa.fa-times  
                      |  Close
              .row.mt-3
                .col-12
                  SurveyTemplate(@saved="getData()" v-model="selected", :edit_mode="selected.item_type == 'project_assessments'")

</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import SurveyTemplate from '@/components/SurveyTemplate.vue'
import LineChart from '@/components/LineChart.vue'

import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SurveyTemplates',
  
  data: function () {
      return {
         line_options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            bezierCurve: false,
          
            scales: {
                xAxes: [{
                  type: 'time',
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                  time: {
                      unit: 'day',
                      displayFormats: {
                          month: 'MM/YY'
                      }
                  }
                }],
                yAxes: [{
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                  ticks: {min: 0, stepSize: 1}
                }]
            }
          },
          
          survey_templates: null,
          selected: null,
          new_template_raw: {groups: [], data_fields: [], name: ""},
          new_template: null,
          analytics: {},
          new_mode: true
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    
    this.getData();
  

    if(this.$route.query.survey_id == "new") {
      this.create_survey_template();
    }

    

  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
         

        ]),
  },

  
  methods: {

      get_chart_data(el) {

        return {'labels': Object.keys(el['created_at']), 
        'datasets': [
          {lineTension: 0.2, borderColor: '#43B02A', pointRadius: 3, 'label': 'Created', data: Object.keys(el['created_at']).map((x) => el['created_at'][x] )},
          {lineTension: 0.2, borderColor: '#26890D', pointRadius: 3, 'label': 'Sent', data: Object.keys(el['sent_at']).map((x) => el['sent_at'][x] )},
          {lineTension: 0.2, borderColor: '#2C5234', pointRadius: 3, 'label': 'Opened', data: Object.keys(el['opened_at']).map((x) => el['opened_at'][x] )},
          {lineTension: 0.2, borderColor: '#0076A8', pointRadius: 3, 'label': 'Responded', data: Object.keys(el['responded_at']).map((x) => el['responded_at'][x] )}
        ]}
      
      },

      create_survey_template() {
        this.new_template = Object.assign({}, this.new_template_raw);
        this.selected = this.new_template;
        this.new_mode = true;

      },
    
      getData() {
        
        this.$store.dispatch("start_loading");
        
        Vue.http.get(`${Config.config.base_host}/survey_templates`).then(res => {
            this.survey_templates = res.body;
            
            this.survey_templates.forEach(template => {
              this.get_analytics(template._id);
            });
            
            this.$store.dispatch("stop_loading");
        });

      },

      get_analytics(tid) {
         //let today = new Date().toISOString().substring(0,10);
         Vue.http.post(`${Config.config.base_host}/surveys/analytics`, {query: {survey_template_id: [tid]}, start_date: "2020-10-01", end_date: "2020-11-01", interval: "D"}).then(res => {
            this.analytics[tid] = res.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        });
      }
     
  },
  components: {
      SurveyTemplate,
      User,
      LineChart
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }

    .kpi {
      min-width: 50px;
      .indicator {
        font-size: 1em;
        font-weight: bold;
      }
      small {
        font-size: 0.75em;
      }
      
      display: inline-block;
    }
</style>