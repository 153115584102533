<template lang="pug">
.main-project()
    Topic2Modal(ref="topicModal")
    ImportModal(ref="importModal")
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu(:class="{shrink: shrink}", id="submenu", v-if="$store.state.user && $route.name != 'survey_external_path'")
 
            .container-xxl

         
                template(v-if="$route.params.topic_id && selectedTopic")
                   
                    .row.no-gutters.submenu-inner
                        .col-7    
                            .headline-submenu.mb-3 
                                .small.text-gray Survey
                                | {{selectedTopic.name}}

                        //.col-5
                                     
                            .float-right

                                b-btn(size="sm", variant="outline-primary", @click="showModal()")#project-add-edit Add topic

                   
                    .row.mt-0(v-if="$route.params.topic_id").no-gutters.submenu-inner
                        
                            .col-md-12    
                                .menu(v-if="selectedTopic")
                                
                                    router-link(:to="'/projects/' + $store.getters.project.id + '/dashboard'").mr-5 Overview
                                    router-link(:to="'/topics/usecases/' +  selectedTopic._id + '/list' ").mr-5 Overview
                                    //router-link(:to="'/topics/usecases/' +  selectedTopic._id + '/assessements'").mr-5 Assessments
                                    //router-link(:to="'/topics/usecases/' +  selectedTopic._id + '/surveys'").mr-5 Surveys
                                    //router-link(:to="'/projects/' + $store.getters.project._id + '/analytics'").mr-5 Analytics
                                    //router-link(:to="'/projects/' + $store.getters.project._id + '/analytics'").mr-5 Save
                                    //router-link(:to="'/projects/' + $store.getters.project._id + '/map'").mr-5 Map
                                    //router-link(:to="'/projects/' + $store.getters.project._id + '/topics'").mr-5 Topics
                                    //router-link(:to="'/projects/' + $store.getters.project._id + '/similar'").mr-5 Discovery <span class="text-muted ml-1">12</span>
                                    //.float-right
                                        router-link(to="setup") Project Setup
                
                .row.mt-0(v-if="!$route.params.topic_id").no-gutters.submenu-inner
                    
                        .col-md-12    
                            .menu()
                            
                                router-link(:to="'/surveys/dashboard'").mr-5 Dashboard
                                //router-link(:to="'/surveys/results'").mr-5 Results
                                //router-link(:to="'/surveys/tracking'").mr-5 Tracking
                                //router-link(:to="'/surveys/details'").mr-5 Survey Details
                                //router-link(:to="'/surveys/tracking'").mr-5 Analytics
                                //router-link(:to="'/surveys/templates'").mr-5 Templates
                            


    .container-xxl(class="d-flex flex-column")
          .row.no-gutters.work-view.sticky-container()
              
              b-col(:cols="sidebar_visible ? 9 : 12 ")
                #affix-container
                
                  router-view(name="main").main-view
              b-col(:cols="12-mainCols", v-if="mainCols < 12")
                  
                  .mt-5(v-if="$route.name != 'survey_external_path'")
                    .sidebar-right(v-bind:class="{'visible': sidebar_visible}", id="sideright").col-3
                      .float-right
                        b-btn(variant="link", @click="toggleSidebar()", size="sm")
                          i.fas.fa-arrow-right(v-show="sidebar_visible")
                          i.fas.fa-arrow-left(v-show="!sidebar_visible")
                      router-view(name="sideview").side-view(v-show="sidebar_visible")
      
</template>

<script>
import Logo from '@/components/Logo';
import User from '@/components/User';
import Topic2Modal from '@/components/Topic2Modal';
import ImportModal from '@/components/ImportModal';
import Config from "@/config";
import Vue from "vue";

import { mapFields } from 'vuex-map-fields'

export default {
  name: 'SubmenuTopic',
  props: {
    title: String,
     
  },
  
  data: function () {
    return {

      shrink: false,
      mainCols: 9,
      filterCols: 2
    }
  },
  
  mounted() {
      if(this.$route.params.topic_id)this.$store.dispatch("loadTopic", this.$route.params.topic_id);
      window.dispatchEvent(new Event('resize'));
  },

 computed: {

        ...mapFields([
            'instance_config',
            'selectedTopic',
            'sidebar_visible'
        ]),
  },

   watch: {
    
    '$route.params.topic_id': function () {
      console.log("y");
      this.$store.dispatch("loadTopic", this.$route.params.topic_id);
      window.dispatchEvent(new Event('resize'));
    }

  },

  beforeMount() {
    
    this.handleDebouncedScroll = this.$_.debounce(this.handleScroll, 10);
    window.addEventListener('scroll', this.handleDebouncedScroll); 
    window.addEventListener('resize', this.handleDebouncedScroll);  
  },


  beforeDestroy() {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
      window.removeEventListener('resize', this.handleDebouncedScroll);
  },

  methods: {  

    toggleSidebar() {
      this.sidebar_visible = !this.sidebar_visible;
      window.dispatchEvent(new Event('resize'));
    },
    handleScroll() {

      
      let el = document.getElementById("submenu");

      if(el) {
        let rect = el.getBoundingClientRect();
        let sel = document.getElementById("sideright")
        sel.style.top = rect.bottom +"px";
        let searchsel = document.getElementById("searchmenu")
        let searchsel_orient = document.getElementById("searchmenu_orient")
        if(searchsel) {
         
          searchsel.style.top = rect.bottom +"px";
          searchsel.style.width = searchsel_orient.clientWidth + "px"
          //searchsel.style.height = "calc(100vh - " +searchsel.style.top + "px)"
        
        }
        
      }
      
    },


    export_project(project) {
    
        this.$store.dispatch("start_loading");
        Vue.http
            .get(Config.config.base_host + "/projects/" + project._id + "/csv")
            .then(resp => {
            console.log(resp);
            var element = document.createElement("a");
                element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(resp.body)
                );
                element.setAttribute("download", project.name+".csv");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

            
            this.$store.dispatch("stop_loading");
            });
    },

    showModal(project) {
        this.$refs.topicModal.open(project);
    },
    
    showImportModal(project) {
        this.$refs.importModal.open(project);
    },


    changed: function(event) {
      this.$store.commit('change', event)
    },
  
 
  },
  
  components: {
      Logo,
      Topic2Modal,
      ImportModal,
      User
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>